import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import FontFace from './font-face';
import Header from './header'
import Footer from './footer'
import { Global, css } from '@emotion/core'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import SiteMeta from './site-meta';

library.config = {
  autoAddCss: false,
};

library.add(fas, fab)

const Layout = ({ children, siteMeta }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
        gatsbyIcon: file(relativePath: { eq: "gatsby-icon.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(
              maxWidth: 430
              quality: 80
              traceSVG: { background: "#f2f8f3", color: "#d6ebd9" }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="en" className="bg-light"/>
          {/* <!-- Meta Pixel Code --> */}
          <script id="facebook-pixel-script">
              {`!function(f, b, e, v, n, t, s) {
                  if(f.fbq) return;
                  n = f.fbq = function() {
                      n.callMethod ?
                          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                  };
                  if (!f._fbq) f._fbq = n;
                  n.push = n;
                  n.loaded = !0;
                  n.version = '2.0';
                  n.queue = [];
                  t = b.createElement(e);
                  t.async = !0;
                  t.src = v;
                  s = b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t, s)
              }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '1073415070185801');
              fbq('track', 'PageView');
            `}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1073415070185801&ev=PageView&noscript=1" />`}
          </noscript>
          {/* <!--End Meta Pixel Code --> */}
          <script async src="https://pop-ups.sendpulse.com/assets/loader.js" data-chats-widget-id="d31343b2-1fb2-465f-8723-4aad7285d418"></script>
        </Helmet>
        <SiteMeta title={data.site.siteMetadata.title} baseUrl={data.site.siteMetadata.siteUrl} siteMeta={siteMeta} />
        <meta name="facebook-domain-verification" content="rottqmnnkfol6yl760n3ujoj7r420c" />
        <FontFace />
        <Header />
        <main className="bg-light">
          <Global
            styles={css`
              :root{
                font-size   : 100%;
                @media (max-width: 575.98px) {
                  font-size : 80%;
                }
                @media (min-width: 576px) and (max-width: 767.98px) {
                  font-size : 90%;
                }
              }
            `}
          />
          {children}
        </main>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-78DCE0P5KZ"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-78DCE0P5KZ');
          `}
        </script>
        <script src="https://cdn.pulse.is/livechat/loader.js" data-live-chat-id="6494f1acbc2009c25d055b74" async></script>
        <script type="text/javascript">
          {`
            _linkedin_partner_id = "4915658";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
        </script>
        <script type="text/javascript">
          {`
            (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4915658&fmt=gif" />`}
        </noscript>
        <script type="text/javascript">
          {`
            script = document.createElement('script')
            script.src = 'https://pop-ups.sendpulse.com/assets/loader.js';
            script.async = true;
            script.setAttribute("data-chats-widget-id",'d31343b2-1fb2-465f-8723-4aad7285d418');
            document.head.appendChild(script);
          `}
        </script>
        <Footer/>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
